import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  } 
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`

const colorAnimation = (color: string) => keyframes`
  0% {
    stroke: ${color};
  }
  40% {
    stroke: ${color};
  }
  66% {
    stroke: ${color};
  }
  80%, 90% {
    stroke: ${color};
  }
`

export const LoadingCircleSvg = styled.svg`
  animation: ${rotate} 2s linear infinite;
  transform-origin: center center;
`

interface LoadingCircleCircleProps {
  readonly color: string
}

export const LoadingCircleCircle = styled.circle<LoadingCircleCircleProps>`
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: ${dash} 1.5s ease-in-out infinite, ${({ color }) => colorAnimation(color)} 6s ease-in-out infinite;
  stroke-linecap: round;
`
