import React from 'react'

import { Background, ArrowDown } from './styles'

interface HeroProps {
  readonly backgroundImage: any
  readonly anchor: string
}

export const Hero = ({ backgroundImage, anchor }: HeroProps) => {
  const scrollTo = (_anchor: string) => {
    document.getElementById(_anchor).scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Background fluid={backgroundImage.childImageSharp.fluid}>
      <ArrowDown onClick={() => scrollTo(anchor)} />
    </Background>
  )
}
