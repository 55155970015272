import React, { useState, useEffect } from 'react'
import { NavigationMenu, NavigationBox, NavigationOffScreen, NavigationLink } from './styles'

import { Hamburger } from './hamburger'
import { NavigationLogo } from './NavigationLogo'
import { CallToAction } from './CallToAction'

interface NavigationProps {
  readonly logo: any
  readonly title: string
  readonly menu: Array<{ title: string; path: string }>
}

export const Navigation = ({ logo, title, menu }: NavigationProps) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const [isScroll, setIsScroll] = useState(false)

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    return winScroll / height
  }

  const handleScroll = () => {
    const scroll = listenToScroll()
    // eslint-disable-next-line no-unused-expressions
    scroll >= 0.02 ? setIsScroll(true) : setIsScroll(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll())
    return window.removeEventListener('scroll', () => handleScroll())
  }, [])

  return (
    <NavigationBox isScroll={isScroll} isNavigationOpen={isNavigationOpen}>
      <NavigationMenu isNavigationOpen={isNavigationOpen}>
        {menu.map((linkobj, index) => {
          return (
            <NavigationLink
              key={String(index)}
              onClick={() => setIsNavigationOpen(!isNavigationOpen)}
              to={linkobj.path}
            >
              {linkobj.title}
            </NavigationLink>
          )
        })}
        <CallToAction label='Anmelden' url="#kurse" />
      </NavigationMenu>
      <Hamburger onClickHandler={() => setIsNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
      <NavigationLogo title={title} logo={logo} isScroll={isScroll} />
      <NavigationOffScreen onClick={() => setIsNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
    </NavigationBox>
  )
}
