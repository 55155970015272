import React from 'react'

import { FooterLink, FooterLinkWrapper } from './styles'

interface LinksProps {
  links: Array<{ title: string; path: string }>
}

export const FooterLinks = ({ links }: LinksProps) => (
  <FooterLinkWrapper>
    {links.map((linkobj, index) => {
      return (
        <FooterLink key={String(index)} to={linkobj.path}>
          {linkobj.title}
        </FooterLink>
      )
    })}
  </FooterLinkWrapper>
)
