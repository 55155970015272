import styled from 'styled-components'

interface ModalProps {
  readonly isVisible: boolean
}

export const PopUpOffScreen = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const PopUpBox = styled.form<ModalProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  max-width: 800px;
  max-height: 100vh;
  flex-direction: column;
  box-shadow: 0 0.6px 0.6px rgba(0, 0, 0, 0.062), 0 1.5px 1.5px rgba(0, 0, 0, 0.089), 0 2.9px 2.9px rgba(0, 0, 0, 0.11),
    0 5.1px 5.1px rgba(0, 0, 0, 0.131), 0 9.6px 9.6px rgba(0, 0, 0, 0.158), 0 23px 23px rgba(0, 0, 0, 0.22);
  background-color: white;
  padding: 2rem;
  border-radius: 5px;
  z-index: 1000;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  overflow-y: scroll;
`

export const PopUpHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`

export const PopUpHeading = styled.div`
  color: #761420;
  font-weight: bold;
  font-size: 1.2rem;
`
export const PopUpSubHeading = styled.div`
  margin-bottom: 1rem;
`

export const PopUpRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`

interface PopUpInputProps {
  readonly width?: number
}

export const PopUpInput = styled.input<PopUpInputProps>`
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  box-sizing: border-box;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  border: 1px solid #ccc;
`

export const PopUpSelect = styled.select<PopUpInputProps>`
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  box-sizing: border-box;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  border: 1px solid #ccc;
`

interface ButtonProps{
  readonly isDisabled?: boolean
}

export const PopUpButton = styled.button`
  cursor:  ${({disabled}) => disabled ? 'auto' : 'pointer' };
  position: relative;
  transition: 0.3s ease-in-out;
  height: 60px;
  overflow: hidden;
  padding: 1.5rem 0.5rem;
  width: 100%;
  background-color: black;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  text-decoration: none;
  color: white;
  margin-top: 1rem;
  opacity:  ${({disabled}) => disabled ? '0.5' : '1' };
`

export const PopUpText = styled.p`
  margin: 0.4rem 0 0 0;
`
export const PopUpSmallText = styled.p`
  font-size: 0.7rem;
  margin: 0.4rem 0 0 0;
`
export const PopUpAlertBox = styled.div`
  margin-top: 0.8rem;
`
