import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'

// eslint-disable-next-line react/jsx-props-no-spreading
export const NavigationLink = styled((props) => <Link {...props} />)`
  transition: all 0.3s;
  text-decoration: none;
  font-size: 0.875rem;
  color: rgb(34, 34, 34);
  font-weight: 800;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  padding: 1rem;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    padding: 0.5rem;
  }
`
