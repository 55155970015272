import React from 'react'
import { Link } from 'gatsby'

import { NavigationTitle, NavigationLogoBox, NavigationLogo as DefaultLogo } from './styles'

interface LogoProps {
  logo: any
  isScroll: boolean
  title: string
}

export const NavigationLogo = ({ logo, title, isScroll }: LogoProps) => (
  <NavigationLogoBox isScroll={isScroll}>
    <Link to="/">
      {logo ? (
        <DefaultLogo
          fluid={logo.childImageSharp.fluid}
          style={{
            backgroundSize: 'contain',
          }}
        />
      ) : (
        <NavigationTitle>{title}</NavigationTitle>
      )}
    </Link>
  </NavigationLogoBox>
)
