import React from 'react'
import { FooterContainer } from './styles'

import { FooterCopyright } from './FooterCopyright'
import { FooterLinks } from './FooterLinks'

interface FooterProps {
  readonly copyright: string
  readonly links: Array<{ title: string; path: string }>
}

export const Footer = ({ copyright, links }: FooterProps) => (
  <FooterContainer>
    <FooterCopyright copyright={copyright} />
    <FooterLinks links={links} />
  </FooterContainer>
)
