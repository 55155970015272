import React, { useState } from "react";
import { CoursesPopUp } from "./CoursesPopUp";
import {
  CourseBoxDescription,
  CourseBoxPrice,
  CourseBoxTitle,
  CoursesBox,
  CoursesBoxAvailable,
  CoursesBoxPriceBox,
  CoursesBoxText,
  CoursesLink,
} from "./CoursesStyles";

interface Props {
  readonly courses: Array<{
    active: boolean;
    places: number;
    text: string;
    title: string;
    price: number;
  }>;
}

export const Courses = ({ courses }: Props) => {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({ title: "", text: "" });
  const isAvailable = courses.length > 0;

  const getAvailableText = (places) => {
    if (places > 1) {
      return `${places} Plätze verfügbar`;
    }
    if (places === 1) {
      return `${places} Platz verfügbar`;
    }

    return `Momentan sind keine Plätze verfügbar`;
  };
  const getAvailableColor = (places) => {
    if (places >= 5) {
      return "green";
    }
    if (places >= 1) {
      return "#f9ca24";
    }
    if (places === 0) {
      return "#eb4d4b";
    }
    return "black";
  };

  return isAvailable ? (
    <>
      <CoursesPopUp
        isVisible={isPopUpVisible}
        course={selectedCourse}
        onCloseClickHandler={() => setIsPopUpVisible(false)}
      />
      {courses.length > 0 &&
        courses.map(
          ({ active, places, text, title, price }, index) =>
            active && (
              <CoursesBox
                onClick={() => {
                  if (places > 0) {
                    setSelectedCourse({ text, title });
                    setIsPopUpVisible(true);
                  }
                }}
                key={index}
              >
                <CoursesBoxText>
                  <CourseBoxTitle>{title}</CourseBoxTitle>
                  <CourseBoxDescription>{text}</CourseBoxDescription>
                </CoursesBoxText>
                <CoursesBoxAvailable>
                  <CourseBoxTitle color={getAvailableColor(places)}>
                    {getAvailableText(places)}
                  </CourseBoxTitle>
                  <CoursesBoxPriceBox>
                    <CourseBoxPrice>{`${price} € `}</CourseBoxPrice>
                  </CoursesBoxPriceBox>
                </CoursesBoxAvailable>
              </CoursesBox>
            )
        )}
    </>
  ) : (
    <>Es sind aktuell keine Kurse verfügbar</>
  );
};
