import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

export const Background = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  display: flex;
  /* padding: 2rem; */
`

export const ArrowDown = styled.div`
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: auto;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: pulse 3s ease-in-out infinite;
  @keyframes pulse {
    0% {
      transform: rotate(45deg) scale(1, 1);
    }
    50% {
      transform: rotate(45deg) scale(1.3, 1.3);
    }
    100% {
      transform: rotate(45deg) scale(1, 1);
    }
  }
`
