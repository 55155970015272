import React from 'react'

import { HamburgerBox, HamburgerBar } from './styles'

interface HamburgerProps {
  readonly isNavigationOpen: boolean
  readonly onClickHandler: () => void
}

export const Hamburger = ({ isNavigationOpen, onClickHandler }: HamburgerProps) => (
  <HamburgerBox onClick={onClickHandler} isNavigationOpen={isNavigationOpen}>
    <HamburgerBar number={1} isNavigationOpen={isNavigationOpen} />
    <HamburgerBar number={2} isNavigationOpen={isNavigationOpen} />
    <HamburgerBar number={3} isNavigationOpen={isNavigationOpen} />
  </HamburgerBox>
)
