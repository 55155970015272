import styled from 'styled-components'
import Image from 'gatsby-image'

interface LogoBoxProps {
  isScroll: boolean
}

// eslint-disable-next-line react/jsx-props-no-spreading
export const NavigationLogoBox = styled.div<LogoBoxProps>`
  transition: all 0.3s;
  margin: 0.5rem 1rem;
  width: ${(props) => (props.isScroll ? '150px' : '180px')};
  @media (min-width: 768px) {
    margin: 0rem;
    width: ${(props) => (props.isScroll ? '150px' : '220px')};
  }
`

export const NavigationLogo = Image
