import styled from 'styled-components'

interface NavigationMenuProps {
  readonly isNavigationOpen: boolean
}

export const NavigationMenu = styled.nav<NavigationMenuProps>`
  display: flex;
  position: absolute;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  top: 100%;
  width: 200px;
  z-index: 998;
  background-color: white;
  transform: ${(props) => (!props.isNavigationOpen ? 'translateX(200px)' : 'translateX(0)')};

  @media (min-width: 768px) {
    position: static;
    display: flex;
    width: auto;
    top: 0;
    width: auto;
    height: auto;
    flex-direction: row;
    background: none;
    animation: none;
    transform: none;
  }
`
