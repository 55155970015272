import styled from 'styled-components'

export const Box = styled.div`
  margin: 1rem 0;
`

export const AdressText = styled.div`
  text-align: justify;
  hyphens: auto;
  white-space: pre;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  @media (min-width: 768px) {
    margin-top: 0;
    padding-left: 2rem;
  }
`

export const AdressContainer = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: flex;
  }
`
