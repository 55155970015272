import styled from 'styled-components'

export const Container = styled.div`
  background: white;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 4rem 1rem;
  @media (min-width: 567px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    padding: 6rem 1rem 0rem;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`
