import styled from 'styled-components'

import DefaultImage from 'gatsby-image'
import DefaultBackgroundImage from 'gatsby-background-image'

interface ImageProps {
  position: boolean
}

export const Image = styled(DefaultImage)<ImageProps>`
  float: ${(props) => (props.position ? 'right' : 'left')};
  border: 1px solid black;
  padding: 1rem;
  max-width: 100%;
  margin-top: ${(props) => (props.position === false ? '0.25rem' : '0.35rem')};
  /* margin-bottom: 0.85rem; */
  margin-right: ${(props) => (props.position === false ? '1rem' : 0)};
  @media (min-width: 768px) {
    margin-right: ${(props) => (props.position === false ? '1.6rem' : 0)};
    margin-bottom: ${(props) => (props.position === false ? 0 : '0.7rem')};
    margin-left: ${(props) => (props.position === false ? 0 : '2rem')};
  }
`

interface SectionProps {
  prevImage?: boolean
  nextImage?: boolean
}

export const Section = styled.div<SectionProps>`
  max-width: 768px;
  padding-top: ${(props) => (props.prevImage ? '3rem' : '0')};
  padding-bottom: ${(props) => (props.nextImage ? '4.5rem' : '0')};
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  margin: 0px auto;
`

export const Text = styled.div`
  text-align: justify;
  hyphens: auto;
  font-size: 1rem;
  line-height: 1.5;
`

export const ImageBox = styled.div`
  position: relative;
  width: 100vw;
  height: 50vh;
`

export const BackgroundImage = styled(DefaultBackgroundImage)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  // https://github.com/timhagn/gatsby-background-image/issues/71
  &,
  &::before,
  &::after {
    background-attachment: fixed;
  }

  @media screen and (max-width: 1200px) {
    &,
    &::before,
    &::after {
      background-attachment: scroll;
    }
  }
`
