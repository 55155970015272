import styled from 'styled-components'

interface Props {
  readonly isNavigationOpen: boolean
}

export const NavigationOffScreen = styled.div<Props>`
  position: absolute;
  top: 100%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: ${(props) => !props.isNavigationOpen && 'none'};
  @media (min-width: 768px) {
    display: none;
  }
`
