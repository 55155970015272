import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Section, ImageBox, BackgroundImage, Image, Text } from './styles'

interface SectionItemProps {
  title: string
  anchor: string
  text: string
  picture: any
  pictureintext: string
  pictureintextposition: boolean
  prevImage: boolean
  nextImage: boolean
}

export const SectionItem = ({
  title,
  anchor,
  text,
  picture,
  pictureintext,
  pictureintextposition,
  prevImage,
  nextImage,
}: SectionItemProps) => (
  <>
    <Section id={anchor} prevImage={prevImage} nextImage={nextImage}>
      <h2>{title}</h2>
      <Text>
        {pictureintext && (
          <Image position={pictureintextposition} fixed={pictureintext.imageUrl.childImageSharp.fixed} />
        )}
        <ReactMarkdown source={text} />
      </Text>
    </Section>
    {picture && (
      <ImageBox>
        <BackgroundImage fluid={picture.imageUrl.childImageSharp.fluid} />
      </ImageBox>
    )}
  </>
)
