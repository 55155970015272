import styled from 'styled-components'

export const CoursesBox = styled.div`
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 1.5rem;
  padding: 1rem;
  box-shadow: 0.7px 0.7px 2.2px rgba(0, 0, 0, 0.02),
    1.6px 1.6px 5.3px rgba(0, 0, 0, 0.028), 3px 3px 10px rgba(0, 0, 0, 0.035),
    5.4px 5.4px 17.9px rgba(0, 0, 0, 0.042),
    10px 10px 33.4px rgba(0, 0, 0, 0.05), 24px 24px 80px rgba(0, 0, 0, 0.07);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`
export const CoursesBoxText = styled.div``

interface CourseBoxTitleProps {
  color?: string
}

export const CourseBoxTitle = styled.h2<CourseBoxTitleProps>`
  margin: 0;
  margin-bottom: 1rem;
  color: ${({ color }) => (color ? color : 'black')};
`

export const CourseBoxDescription = styled.p`
  white-space: pre-wrap;
  margin: 0;
`
export const CoursesBoxAvailable = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CoursesBoxPriceBox = styled.div``


export const CourseBoxPrice = styled.p`
  margin: 0;
  font-weight: bold;
`


export const CoursesLink = styled.div`
  text-decoration: underline;
  font-size: 0.9rem;
`
