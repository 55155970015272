import React, { useCallback, useEffect, useState } from 'react'

import { Button } from '../../Button'
import { Alert } from '../../Alert'

import { ContactFormButtonRow, LoadingBox } from '../ContactFormStyles'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { LoadingCircle } from './LoadingCircle'

interface ContactFormButtonProps {
  readonly messageState: boolean
  readonly messageSuccess: boolean
  readonly messageContent: string
}

export const ContactFormButton = ({
  messageState,
  messageSuccess,
  messageContent,
}: ContactFormButtonProps) => {
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(false)
  return (
    <>
      <GoogleReCaptcha onVerify={() => setIsSendButtonEnabled(true)} />
      <ContactFormButtonRow isFlex>
        {isSendButtonEnabled ? (
          <Button isHoverDisabled>Abschicken</Button>
        ) : (
          <LoadingBox>
            <LoadingCircle color="black" width="50px" height="50px" />
            Bitte warten
          </LoadingBox>
        )}
        <Alert
          isVisible={messageState}
          isSuccess={messageSuccess}
          message={messageContent}
        />
      </ContactFormButtonRow>
    </>
  )
}
