import styled from 'styled-components'

interface ModalProps {
  isDisabled: boolean
}

export const OffScreen = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: ${(props) => (props.isDisabled ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const ModalBox = styled.div<ModalProps>`
  display: ${(props) => (props.isDisabled ? 'none' : 'flex')};
  width: 300px;
  flex-direction: column;
  box-shadow: 0 0.6px 0.6px rgba(0, 0, 0, 0.062), 0 1.5px 1.5px rgba(0, 0, 0, 0.089), 0 2.9px 2.9px rgba(0, 0, 0, 0.11),
    0 5.1px 5.1px rgba(0, 0, 0, 0.131), 0 9.6px 9.6px rgba(0, 0, 0, 0.158), 0 23px 23px rgba(0, 0, 0, 0.22);
  background-color: white;
  padding: 1rem;
`

export const ModalTitle = styled.h3`
  margin-bottom: 0.5rem;
`

export const ModalText = styled.p``
