import styled from 'styled-components'

export const CallToActionButton = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  background-color: #761420;
  border-radius: 5px;
  /* border: 1px solid black; */
  padding: 0.6rem;
  box-shadow: 0.7px 0.7px 2.2px rgba(0, 0, 0, 0.02),
    1.7px 1.7px 5.3px rgba(0, 0, 0, 0.028),
    3.3px 3.1px 10px rgba(0, 0, 0, 0.035),
    5.8px 5.6px 17.9px rgba(0, 0, 0, 0.042),
    10.9px 10.4px 33.4px rgba(0, 0, 0, 0.05), 26px 25px 80px rgba(0, 0, 0, 0.07);
`
