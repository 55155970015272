import styled from 'styled-components'

interface IFrameBoxProps {
  aspectRaito: {
    width: number
    height: number
  }
}

export const IFrameBox = styled.div<IFrameBoxProps>`
  position: relative;
  padding-top: ${(props) => `${(props.aspectRaito.height / props.aspectRaito.width) * 100}%`};
`

export const IFrameWrapper = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
export const IFrameEmtpyWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: gray;
`
