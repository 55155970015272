import styled from 'styled-components'

interface NavigationProps {
  readonly isScroll: boolean
  readonly isNavigationOpen: boolean
}

export const NavigationBox = styled.div<NavigationProps>`
  background-color: ${({ isScroll }) => (isScroll ? 'white' : 'rgba(255, 255, 255, 0.7)')};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s;
  flex-direction: row-reverse;
  box-shadow: 0 0.6px 0.6px rgba(0, 0, 0, 0.062), 0 1.5px 1.5px rgba(0, 0, 0, 0.089), 0 2.9px 2.9px rgba(0, 0, 0, 0.11),
    0 5.1px 5.1px rgba(0, 0, 0, 0.131), 0 9.6px 9.6px rgba(0, 0, 0, 0.158), 0 23px 23px rgba(0, 0, 0, 0.22);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  @media (min-width: 768px) {
    padding: 0 5vw;
  }
`
