import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { Link } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { Button } from '../Button'
import { useCookieState, useCookieDispatch } from '../CookieLaw'

import {
  ContactFormBox,
  ContactFormText,
  ContactFormRow,
  ContactInput,
  ContactTextArea,
  ContactFormButtonRow,
} from './ContactFormStyles'
import { ContactFormButton } from './ContactFormButton'

interface ContactFormProps {
  readonly mailerAdress: string
}

export const ContactForm = ({ mailerAdress }: ContactFormProps) => {
  const state = useCookieState()
  const dispatch = useCookieDispatch()

  const [messageState, setMessageState] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState(false)
  const [messageContent, setMessageContent] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.target)
    if (mailerAdress) {
      fetch(mailerAdress, {
        method: 'POST',
        // headers: { 'content-type': 'application/json' },
        mode: 'cors',
        body: data,
      }).then((res) =>
        res.text().then((text) => {
          setMessageContent(text)
          setMessageState(true)
        })
      )
    } else {
      // DEBUG
      // this.fakeFulfillment()
      // this.fakeRejection()
    }

    setTimeout(() => setMessageState(false), 5000)
  }

  const fakeFulfillment = () => {
    setMessageSuccess(true)
    setMessageState(true)
    setMessageContent('Die Nachricht ist angekommen. Vielen Dank!')
  }

  const fakeRejection = () => {
    setMessageSuccess(false)
    setMessageState(true)
    setMessageContent(
      'Die Nachricht ist nicht angekommen. Bitte versuchen Sie es später erneut oder nutzen Sie unsere anderen Kontaktmöglichkeiten!'
    )
  }

  const onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cookie = new Cookies()

    if (cookie.get('cookieLaw') && e.target.checked) {
      dispatch({ type: 'enableCaptcha' })
    } else if (!cookie.get('cookieLaw') && e.target.checked) {
      dispatch({ type: 'enableCaptcha' })
    } else if (!cookie.get('cookieLaw') && !e.target.checked) {
      dispatch({ type: 'disableCaptcha' })
    }
  }

  return (
    <ContactFormBox>
      <ContactFormText>Kontaktformular</ContactFormText>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ContactInput
          id="name"
          name="name"
          type="text"
          placeholder="Vor- und Nachname"
          required
        />
        <ContactInput
          id="email"
          name="email"
          type="email"
          placeholder="maxmusterman@example.com"
          required
        />
        <ContactTextArea
          id="message"
          name="message"
          rows={4}
          placeholder="Hier können Sie Ihre Nachricht für mich eingeben"
          required
        />
        <div style={{ flex: 1, alignItems: 'center' }}>
          Ich habe die <Link to="/datenschutz">Datenschutzerklärung</Link>{' '}
          gelesen und akzeptiert
          <input
            onChange={(e) => onCheckBoxChange(e)}
            style={{ marginLeft: '1rem' }}
            id="datenschutz"
            name="datenschutz"
            type="checkbox"
            required
          />
        </div>
        {state.isCaptchaEnabled ? (
          // LOCAL: <GoogleReCaptchaProvider reCaptchaKey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI">
          <GoogleReCaptchaProvider reCaptchaKey="6Lf_uYMcAAAAAComqGVsEjzDRzkn55sRayqxKQjr">
            <ContactFormButton
              messageContent={messageContent}
              messageState={messageState}
              messageSuccess={messageSuccess}
            />
          </GoogleReCaptchaProvider>
        ) : (
          <ContactFormButtonRow isFlex>
            <Button disabled>Abschicken</Button>
          </ContactFormButtonRow>
        )}
      </form>
    </ContactFormBox>
  )
}
