import React from 'react'

import { Footer as DefaultFooter } from '../components'

const footerLinks = [
  { title: 'Impressum', path: '/impressum' },
  { title: 'Datenschutz', path: '/datenschutz' },
]

const Footer = () => (
  <DefaultFooter
    copyright={`© 2017-${new Date().getFullYear().toString()} Anja Wirth. Alle Rechte vorbehalten.`}
    links={footerLinks}
  />
)

export { Footer }
