import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'

import { Alert } from '../../Alert'

import { Cross } from './Cross'
import {
  PopUpOffScreen,
  PopUpBox,
  PopUpRow,
  PopUpInput,
  PopUpButton,
  PopUpHeading,
  PopUpSubHeading,
  PopUpSmallText,
  PopUpText,
  PopUpHeadingRow,
  PopUpAlertBox,
  PopUpSelect,
} from './CoursesPopUpStyles'

interface ModalProps {
  readonly isVisible: boolean
  readonly onCloseClickHandler: () => void
  readonly course: {
    text: string
    title: string
  }
}

export const CoursesPopUp = ({
  isVisible,
  onCloseClickHandler,
  course,
}: ModalProps) => {
  const [messageState, setMessageState] = useState(false)
  const [messageContent, setMessageContent] = useState('')
  const [messageSuccess, setMessageSuccess] = useState(true)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()
  const watchComplaints = watch('complaints', 'Nein')

  useEffect(()=> {
    setValue('course', course.title)
  }, [course])

  const onSubmit = (fData) => {
    const data = new FormData()

    for (const key in fData) {
      if (key === 'field') {
        data.append(key, fData[key][1])
      } else {
        data.append(key, fData[key])
      }
    }
    fetch('https://mailer.anjawirth.de/yoga_course.php', {
      method: 'POST',
      mode: 'cors',
      body: data,
    })
      .then((res) => {
        if (res.ok) {
          return res.text()
        }
        throw new Error('Ein Fehler ist aufgetreten')
      })
      .then((text: string) => {
        setMessageSuccess(true)
        setMessageState(true)
        setMessageContent(text)
      })
      .catch(() => {
        setMessageSuccess(false)
        setMessageState(true)
        setMessageContent('Ein Fehler ist aufgetreten')
      })
    setTimeout(() => setMessageState(false), 5000)
  }

  const fakeFulfillment = () => {
    setMessageSuccess(true)
    setMessageState(true)
    setMessageContent('Ihre Nachricht ist angekommen. Vielen Dank!')
  }

  const fakeRejection = () => {
    setMessageSuccess(false)
    setMessageState(true)
    setMessageContent(
      'Ihre Nachricht ist leider nicht angekommen. Bitte versuchen Sie es später erneut oder nutzen Sie unsere anderen Kontaktmöglichkeiten.'
    )
  }

  return (
    <>
      <PopUpOffScreen isVisible={isVisible} onClick={onCloseClickHandler} />
      <PopUpBox isVisible={isVisible} onSubmit={handleSubmit(onSubmit)}>
        <PopUpHeadingRow>
          <PopUpHeading>Buchungsformular</PopUpHeading>
          <Cross onClick={onCloseClickHandler} />
        </PopUpHeadingRow>
        <PopUpSubHeading>
          Bitte beachten Sie, dass Ihre Anmeldung erst nach einer Bestätigung
          gültig ist.
        </PopUpSubHeading>
        <PopUpRow>
          <PopUpInput
            type="text"
            placeholder="Vor- und Nachname"
            {...register('name', { required: true })}
          />
        </PopUpRow>
        <PopUpRow>
          <PopUpInput
            type="email"
            placeholder="maxmusterman@example.com"
            {...register('email', { required: true })}
          />
        </PopUpRow>
        <PopUpRow>
          <PopUpInput
            type="text"
            placeholder="Straße"
            {...register('adress', { required: true })}
          />
        </PopUpRow>
        <PopUpRow>
          <PopUpInput
            type="number"
            placeholder="Postleitzahl"
            {...register('zipcode', { required: true })}
            width={49}
          />
          <PopUpInput
            type="text"
            placeholder="Stadt"
            {...register('city', { required: true })}
            width={49}
          />
        </PopUpRow>
        <PopUpRow>
          <PopUpInput
            type="text"
            placeholder="Telefonnummer"
            {...register('phone', { required: true })}
          />
        </PopUpRow>
        <PopUpRow>
          <PopUpSelect
            {...register('yogaerfahrung', { required: true })}
            width={29}
          >
            <option value="Anfänger">Yoga-Anfänger</option>
            <option value="Fortgeschritten">Yoga-Erfahrener</option>
          </PopUpSelect>
          <PopUpSelect
            {...register('complaints', { required: true })}
            width={69}
          >
            <option value="Nein">
              Nein, ich habe keine körperliche Beschwerden
            </option>
            <option value="Ja">Ja, ich habe körperliche Beschwerden</option>
          </PopUpSelect>
        </PopUpRow>
        {watchComplaints === 'Ja' && (
          <PopUpRow>
            <PopUpInput
              type="text"
              placeholder="Welche Beschwerden?"
              {...register('complaintsText')}
            />
          </PopUpRow>
        )}
        <PopUpText>
          Jeder Teilnehmer kommt aus freier Selbstverantwortung. Für Personen-
          und Sachschäden wird keine Haftung übernommen. Bei Überbuchung gilt
          die Reihenfolge der schriftlichen Anmeldungseingänge.
        </PopUpText>
        <PopUpText style={{ flex: 1, alignItems: 'center' }}>
          Hiermit akzeptiere ich die obigen Bedingungen und die{' '}
          <Link to="/datenschutz">Datenschutzerklärung</Link>.
          <input
            style={{ marginLeft: '1rem' }}
            type="checkbox"
            required
          />
        </PopUpText>
        <PopUpButton type="submit">Abschicken</PopUpButton>
        {messageState && (
          <PopUpAlertBox>
            <Alert
              isVisible={messageState}
              isSuccess={messageSuccess}
              message={messageContent}
            />
          </PopUpAlertBox>
        )}
      </PopUpBox>
    </>
  )
}
