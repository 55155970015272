import styled from 'styled-components'

export const ContactFormBox = styled.div`
  /* padding-bottom: 4rem; */
  margin-top: 2rem;
`

interface ContactFormRowProps {
  isFlex?: boolean
  isLast?: boolean
}

export const ContactFormRow = styled.label<ContactFormRowProps>`
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
  width: 100%;
  margin-bottom: ${(props) => (props.isLast ? '0' : '1rem')};
  align-items: center;
  justify-content: space-between;
  min-height: ${(props) => (props.isFlex ? '4.5rem' : '0')};
  .alert {
    margin-bottom: 0;
  }
  &:last-child {
    margin: 0;
  }
`
export const ContactFormButtonRow = styled.div<ContactFormRowProps>`
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};
  width: 100%;
  margin-bottom: ${(props) => (props.isLast ? '0' : '1rem')};
  align-items: center;
  justify-content: space-between;
  min-height: ${(props) => (props.isFlex ? '4.5rem' : '0')};
  .alert {
    margin-bottom: 0;
  }
  &:last-child {
    margin: 0;
  }
`

export const ContactFormText = styled.h2`
  margin-bottom: 1rem;
  /* font-size: ${(props) => props.theme.textSize}; */
`

export const ContactInput = styled.input`
  padding: 12px 20px;
  margin: 8px 0px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
`

export const ContactFileInput = styled.input`
  margin-top: 0.2rem;
  width: 100%;
`

export const ContactTextArea = styled.textarea`
  resize: none;
  padding: 12px 20px;
  margin: 8px 0px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
`

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
`
