import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import "@fontsource/libre-franklin";
import "@fontsource/libre-franklin/800.css";

import { GlobalStyle, CookieLaw, CookieProvider } from "../components";

import { Footer } from "./_footer";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', { offset: () => 100 });
}

interface DefaultLayoutType {
  children: React.ReactNode;
}

export const DefaultLayout = ({ children }: DefaultLayoutType) => (
  <CookieProvider>
    <GlobalStyle />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Yogastudio Monheim | Yogakurse | Praeventionskurse</title>
      <meta
        name="description"
        content="Yoga ist wie Zähneputzen für den Körper. Sie möchten Ihre Körperhaltung verbessern? Ein bisschen mehr Entspannung? Zur Ruhe kommen?"
      />
    </Helmet>
    <CookieLaw />
    {children}
    <Footer />
  </CookieProvider>
);
