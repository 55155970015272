import React from "react";

import { ContactForm } from "../ContactForm";
import { Courses } from "../Courses";
import { MapsIFrame } from "./MapsIFrame";
import { SectionItem } from "./SectionItem";

import { Section } from "./SectionItem/styles";
import { Box, AdressText, AdressContainer } from "./SectionsStyles";
import ReactMarkdown from "react-markdown";

interface SectionsProps {
  readonly sectionlist: Array<{
    anchor: string;
    id: string;
    order: number;
    picture: any;
    text: string;
    title: string;
    pictureintext: any | null;
    pictureintextposition: boolean | null;
  }>;
  readonly adress: {
    title: string;
    adress: string;
    text: string;
  };
  readonly courses: Array<{
    active: boolean;
    places: number;
    text: string;
    title: string;
    price: number;
  }>;
  readonly coursestext: {
    text: string;
  };
}

const renderers = {
  link: (props) => (
    <a href={props.href} target="_blank">
      {props.children}
    </a>
  ),
};

export const Sections = ({
  sectionlist,
  adress,
  courses,
  coursestext,
}: SectionsProps) => {
  const checkImage = (index: number) => {
    if (index <= 0 || index >= sectionlist.length) return true;
    if (sectionlist[index].picture === null) return false;
    return true;
  };

  const sortSections = (sectionOne, sectionTwo) => {
    if (sectionOne.order < sectionTwo.order) return -1;
    if (sectionOne.order > sectionTwo.order) return 1;
    return 0;
  };

  const onLinkClick = (event) => {
    event.stopPropagation();
    const anchorElement = document.getElementById("praeventionskurs");
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box>
      {sectionlist &&
        sectionlist
          .sort(sortSections)
          .map(
            (
              {
                title,
                text,
                anchor,
                picture,
                pictureintext,
                pictureintextposition,
              },
              index
            ) => {
              return (
                <SectionItem
                  key={String(index)}
                  title={title}
                  text={text}
                  anchor={anchor}
                  picture={picture}
                  pictureintext={pictureintext}
                  pictureintextposition={pictureintextposition}
                  prevImage={checkImage(index - 1)}
                  nextImage={checkImage(index)}
                />
              );
            }
          )}
      <Section id="kurse">
        <h2 style={{ marginBottom: "2rem" }}>Kurse</h2>
        <div style={{ marginBottom: "0.5rem" }}>
          <span>
            Mehr Informationen zur Kostenübernahme durch Ihre Krankenkasse
            finden Sie{" "}
          </span>
          <span style={{ textDecoration: "underline" }} onClick={onLinkClick}>
            hier.
          </span>
        </div>
        <div style={{ marginBottom: "1rem" }}>
          Zur Anmeldung wählen Sie einen der Kurse aus:
        </div>
        <Courses courses={courses} />
        <div style={{ marginBottom: `1.5rem` }}>
          <ReactMarkdown renderers={renderers} source={coursestext.text} />
        </div>
      </Section>
      <Section id="anfahrt">
        <h2>{adress.title}</h2>
        <AdressContainer>
          <div style={{ flex: 1 }}>
            <MapsIFrame
              link={adress.adress}
              title={adress.title}
              aspectRaito={{ width: 16, height: 9 }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <AdressText>{adress.text}</AdressText>
          </div>
        </AdressContainer>
        <ContactForm mailerAdress="https://mailer.anjawirth.de/yoga.php" />
      </Section>
    </Box>
  );
};
