import styled from 'styled-components'

interface ButtonProps {
  readonly disabled?: boolean
  readonly isHoverDisabled?: boolean
  readonly color?: 'red' | 'green'
}

const getBackgroundColor = (color: string) => {
  switch (color) {
    default:
      return 'rgba(118, 20, 32, 0.7)'
    case 'red':
      return '#c0392b'
    case 'green':
      return '#70ad58'
  }
}
const getBorderColor = (color: string) => {
  switch (color) {
    default:
      return '#761420'
    case 'red':
      return '#e74c3c'
    case 'green':
      return '#207614'
  }
}

export const Button = styled.button<ButtonProps>`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition: transform 0.25s ease-out, background 0.25s ease-out;
  border: 2px solid #761420;
  border-radius: 5px;
  background-color: white;
  color: black;
  border-color: ${({ color }) => getBorderColor(color)};
  padding: 0.4rem 1.25rem;
  font-weight: 600;
  box-shadow: rgb(0 0 0 / 2%) 0px 1.2px 2.2px, rgb(0 0 0 / 3%) 0px 2.8px 5.3px,
    rgb(0 0 0 / 4%) 0px 5.3px 10px, rgb(0 0 0 / 4%) 0px 9.4px 17.9px,
    rgb(0 0 0 / 5%) 0px 17.5px 33.4px, rgb(0 0 0 / 7%) 0px 42px 80px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  &:hover {
    transform: ${({ disabled, isHoverDisabled }) =>
      disabled ? 'none' : 'translateY(-2px)'};
    background-color: ${({ color, disabled, isHoverDisabled }) =>
      disabled || isHoverDisabled ? 'transparent' : getBackgroundColor(color)};
  }
  &:focus {
    outline: 0;
  }
`
