import React from 'react'

import { CallToActionButton } from './CallToActionStyles'

interface Props {
  readonly label: string
  readonly url: string
}
export const CallToAction = ({ label, url }: Props) => (
  <CallToActionButton href={url}>{label}</CallToActionButton>
)
